<template>
  <div>
    <el-card shadow="always">
      <div class="top_area">
        <el-input placeholder="请输入需要搜索的标题" style="width: 20%" v-model="searchForm.product_title" clearable></el-input>
        <el-button type="primary" @click="searchProduct">查询</el-button>
      </div>
      <el-table ref="product_info" fit h :data="productData" tooltip-effect="dark" style="width: 100%" v-loading="loading">
        <el-table-column prop="product_title" label="商品标题" min-width="80"> </el-table-column>
        <el-table-column prop="product_cover_img" label="商品封面" min-width="50">
          <template slot-scope="scope">
            <el-image :src="scope.row.product_cover_img" fit="contain" class="cover_img"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="product_price" label="商品单价" min-width="30"> </el-table-column>
        <el-table-column prop="product_stock" label="商品库存" min-width="30"> </el-table-column>
        <el-table-column prop="create_time" label="商品创建时间" min-width="80"> </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="
                centerDialogVisible = true
                downShelves(scope.$index, scope.row)
              "
              >下架</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          small
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ProductList',
  data() {
    return {
      total: 0,
      currentPage: 1,
      page_size: 10,
      loading: false,
      productData: [],
      searchForm: {
        page: 1,
        size: 5,
        product_title: undefined
      }
    }
  },
  created() {
    this.searchProduct()
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val
      this.searchProduct()
    },
    handleSizeChange(val) {
      this.page_size = val
      this.searchProduct()
    },
    searchProduct() {
      this.loading = true
      this.searchForm['page'] = this.currentPage
      this.searchForm['size'] = this.page_size
      this.$api.product.getProductList(this.searchForm).then(res => {
        if (res.data.code === 200) {
          this.productData = res.data.data.list
          this.total = res.data.data.total
          this.$message.success({
            message: '查询成功',
            center: true,
            duration: 500
          })
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
        this.loading = false
      })
    },
    downShelves(index, row) {
      this.$api.product.downProduct({ product_id: row.product_id }).then(res => {
        if (res.data.code === 200) {
          this.$message.success({
            message: '下架成功',
            center: true,
            duration: 2000
          })
          this.searchProduct()
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.top_area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.cover_img {
  width: 80px;
  height: 80px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
